<template>
  <v-container class="ma-0 ml-2 pa-5">
    <app-header>
      <v-btn text @click="$router.back()">
        <v-icon class="pr-1">
          {{ 'mdi-arrow-left' }}
        </v-icon>
        {{ $t('back') }}
      </v-btn>

      <v-btn text @click="saveConfiguration">
        <v-icon class="pr-1" color="success">
          {{ 'mdi-check' }}
        </v-icon>
        {{ $t('save') }}
      </v-btn>
    </app-header>

    <v-expansion-panels v-model="info">
      <v-expansion-panel flat>
        <v-expansion-panel-header>
          <h2 class="primary--text">
            {{ $t('info.header') }}
          </h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-html="$t('info.content')"></div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>


    <v-row style="height: 100%" class="pa-4">
      <v-col md="1">
        <h4>{{ $t('settings.toggle') }}</h4>
      </v-col>
      <v-col md="1">
        <h4>{{ $t('settings.priority') }}</h4>
      </v-col>
      <v-col md="10">
        <h4>{{ $t('settings.name') }}</h4>
      </v-col>

      <v-treeview
          v-model="selected"
          :items="names"
          item-key="indexName"
          item-children="names"
          item-text="label"
          selected-color="primary"
          selection-type="leaf"
          selectable
          open-all
          transition
          return-object
          expand-icon="mdi-chevron-down"
          on-icon="mdi-checkbox-marked"
          off-icon="mdi-checkbox-blank"
          indeterminate-icon="mdi-minus-box"
      >
        <template v-slot:prepend="{item, selected}">
          <v-text-field class="centered-input pl-12" :rules="[rule]" :disabled="!selected"
                        v-if="!item.names || !item.names.length" style="width: 100px;" v-model="item.priority">
          </v-text-field>
        </template>

        <template v-slot:label="{item}">
          <p :class="item.names && item.names.length ? '' : 'ml-16'" style="margin-bottom: 0px;">{{ item.label }}</p>
        </template>
      </v-treeview>

    </v-row>
    <v-snackbar v-model="success" color="success">
      {{ $t('messages.uploadedSuccessfully') }}
    </v-snackbar>

    <v-snackbar v-model="error" color="error">
      {{ $t('messages.somethingWentWrong') }}
    </v-snackbar>
  </v-container>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "ConfigSettings",
  components: {
    AppHeader
  },
  data() {
    return {
      selected: [],
      info: 0,
      success: false,
      error: false,
      errorMessage: '',
      fields: [],
      rule: value => /^[0-9]*$/.test(value) || 'Numbers only! '
    }
  },
  async created() {
    //await this.loadConfiguration();
    //await this.loadColumns();

    if (!this.columns) {
      await this.loadColumns();
    }

    this.checkEnabled(this.names)
  },
  watch: {
    names(value) {
      this.checkEnabled(this.names)
    },
    '$i18n.locale'(value) {
      this.loadColumns().then(() => {
        this.checkEnabled(this.names)
      });
    }
  },
  computed: {
    ...mapGetters({
      getNames: 'main/getNames',
      getConfig: 'main/getConfig',
      getLocale: 'main/getLocale',
      columns: 'main/getColumns'
    }),
    names: {
      set(value) {
        this.setNames(value)
      },
      get() {
        return this.getNames
      }
    },
    config: {
      set(value) {
        this.setConfig(value)
      },
      get() {
        return this.getConfig
      }
    }
  },
  methods: {
    ...mapActions({
      loadColumns: 'main/loadColumns',
      uploadConfiguration: 'main/uploadUserConfig',
      loadConfiguration: 'main/loadConfiguration',
    }),
    ...mapMutations({
      setNames: 'main/setNames',
      setConfig: 'main/setImageConfig'
    }),
    checkEnabled(names) {
      this.selected.push(...names.filter(name => {
        if (typeof name.label !== "string" && name.label)
          name.label = name.label[this.getLocale] || name.label['en-EN'];

        if (name.names) {
          this.checkEnabled(name.names)
        }
        return name.enabled
      }))
    },
    checkChildren(parent) {
      parent.enabled = !!parent.fields.filter(child => {
        if (child.fields && child.fields.length) {
          return !!this.checkChildren(child)
        } else {
          if (this.selected) {
            let found = this.selected.find(item => item.indexName === child.indexName.replaceAll('.0', ''))
            child.enabled = !!found
            if (child.enabled) child.priority = parseInt(found.priority)
            if (child.enabled && !child.priority) return true
          } else {
            child.enabled = false
          }
        }
      }).length
      return parent.enabled
    },
    fail(msg) {
      this.errorMessage = msg
      this.error = true
    },
    saveConfiguration() {
      this.config.form.forEach(name => {
        if (name.fields.length)
          name.enabled = !!this.checkChildren(name)
      })
      this.uploadConfiguration(this.config).then(() => {
        this.success = true
      }).catch((err) => {
        this.fail(err)
      })
    },
  }
}
</script>

<style scoped>
.v-input >>> input {
  text-align: center;
}
</style>